.send-msg-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 22px;
	display: flex;
	flex-wrap: wrap;

	.sm-left {
		flex: 1;
		margin-right: 14px;
		max-width: 900px;
		width: 100%;
		&.sm-left-form-builder {
			max-width: 836px;
		}
	}
	.sm-heading {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		border-bottom: 1px solid #d1d1d1;
		padding-bottom: 15px;
		margin-bottom: 14px;

		span {
			color: #8e9aa3;
			margin-left: 6px;
		}
	}
	.sm-subheading {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: #8e9aa3;
		margin: 0 0 20px;
	}
	.custom-form-group {
		display: flex;
		align-items: center;
		margin: 0 0 14px;

		label {
			font-size: 14px;
			line-height: 14px;
			margin-bottom: 0;
			margin-right: 10px;
			color: #000;
			font-weight: 600;
			flex: 0 0 auto;
			min-width: 180px;

			span {
				color: #8e9aa3;
				margin-left: 6px;
			}
		}
		.input-grp {
			display: flex;
			align-items: center;
			width: 100%;
			> span {
				font-size: 14px;
				line-height: 14px;
				font-weight: 600;
				margin: 0 14px;
			}
		}
		.sm-input-location {
			position: relative;
			flex: 1 1 100%;
			.sm-location-option {
				position: absolute;
				width: 100%;
				z-index: 1000;
				background-color: #fff;
				max-height: 250px;
				overflow-y: auto;
				border-radius: 0 0 15px 15px;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18);
			}
		}
		&.custom-checkbox {
			label {
				line-height: 20px;
				font-size: 14px;
				font-weight: 600;
			}
		}
		&.disabled-form-grp {
			pointer-events: none;
			opacity: 0.7;
		}
		.input-error-wrap {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
	.sm-form-collapse {
		background: transparent;
		.sm-collapse-panel {
			display: flex;
			flex-wrap: wrap;
			border: 0;
			.ant-collapse-header {
				padding: 0;
				display: inline-flex;
				font-size: 16px;
				color: var(--theme-primary);
				font-weight: 600;
				align-items: center;
				margin: 10px 0 14px;

				.ant-collapse-arrow {
					display: none;
				}
				.sm-collapse-icon {
					font-size: 6px;
					margin-left: 6px;
					transition: all 0.3s ease;
				}
			}
			&.ant-collapse-item-active {
				.sm-collapse-icon {
					transform: rotate(-180deg);
				}
			}
			.ant-collapse-content {
				flex: 0 0 100%;
				.ant-collapse-content-box {
					padding: 0;
				}
			}
		}
	}
	.ant-form-item {
		margin: 0;
	}
	.send-msg-editior {
		width: 100%;
		.tox-hugerte {
			height: 260px;
			overflow-y: auto;
		}
	}
	.sm-right {
		margin-left: auto;

		.send-msg-link {
			text-align: center;
			color: var(--theme-primary);
			font-size: 14px;
			line-height: 14px;
			font-weight: 600;
			cursor: pointer;

			&.sm-link-disabled {
				pointer-events: none;
				opacity: 0.7;
				cursor: no-drop;
			}
		}
		&.sm-right-form-builder {
			margin-top: 100px;
		}
	}
	.sm-card-bottom {
		flex: 0 0 100%;
		margin-bottom: 20px;

		.sm-btn-grp {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			button {
				+ button {
					margin-left: 14px;
				}
			}
		}
	}
	.sm-demo-bar {
		flex: 1;
		display: flex;
		max-width: 546px;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 8px;

		.sm-heading {
			padding: 0;
			border: 0;
			margin: 0;
			margin-right: 14px;
		}
	}
	.sm-form-builder {
		display: flex;
		.fb-left {
			flex: 0 0 auto;
			max-width: 546px;
			width: 100%;
			.fb-preview {
				background-color: #fafdff;
				padding: 16px 12px;
				border: 1px solid #c9c9c9;
				border-radius: 10px;
				position: relative;
				.fb-sortable {
					transition: opacity 0.25s ease-in;
					position: relative;
					cursor: move;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					user-select: none;

					.SortableItem {
						position: relative;
						position: relative;
						padding: 10px;
						border: 1px solid #ddd;
						margin: 0 0 10px;
						background: #fff;
						border-radius: 8px;

						.toolbar-header {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin: 0 0 10px;

							.sortable-item-badge {
								margin-right: 14px;
								font-size: 12px;
								line-height: 12px;
								font-weight: 700;
								background: #ddd;
								padding: 4px 6px;
								border-radius: 4px;
								display: inline-block;
								box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
							}
							.toolbar-header-buttons {
								display: flex;
								align-items: center;
								padding: 0;
								list-style: none;
								margin: 0;

								.toolbar-btn {
									padding: 2px 4px;
									font-size: 12px;
									line-height: 1;
									color: var(--theme-primary);
									cursor: pointer;

									&.danger {
										color: var(--flamingo);
									}

									+ .toolbar-btn {
										margin-left: 4px;
									}
								}
							}
						}
					}
				}
				.fb-placeholder {
					background: #f7f7f7;
					padding: 42px 10px;
					margin: 0;
					border: 1px dashed #c9c9c9;
					border-radius: 10px;

					p {
						margin: 0;
						font-size: 14px;
						text-align: center;
						line-height: 18px;
						font-weight: 600;
					}
				}
				&.fb-edit-form-open {
					.fb-edit-form {
						z-index: 1059;
						transition: all 0.1s ease;
					}
					.fb-form-inner {
						transition: all 0.3s 0.1s ease;
						margin-left: 0;
					}
				}
			}
		}
		.fb-right {
			margin-left: 20px;
			width: 100%;
			margin-top: -30px;

			.fb-right-toolbar {
				width: 100%;
				h4 {
					font-size: 16px;
					line-height: 16px;
					font-weight: 700;
					margin: 0 0 14px;
					text-align: left;
				}
				ul {
					margin: 0;
					list-style: none;
					padding: 0;

					li {
						background: #f7f7f7;
						border: 1px dashed #ddd;
						cursor: pointer;
						margin: 5px;
						padding: 10px 16px;

						i {
							margin: 0 15px 0 0;
						}
					}
				}
			}
		}
	}
}
.msg-tab-btnGrp {
	display: inline-flex;
	align-items: center;
	margin: 0 0 20px;
	flex: 0 0 100%;

	.msg-tab-btn {
		font-size: 12px;
		color: #000;
		line-height: 12px;
		border: 1px solid var(--silver-chalice);
		padding: 8px;
		min-width: 150px;
		height: auto;
		border-radius: 15px;

		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: var(--bright-turquoise);
			color: #fff;
			border: 1px solid var(--bright-turquoise);
		}
		+ .msg-tab-btn {
			margin-left: 10px;
		}
	}
}
.msg-notification {
	background-color: #fff;
	padding: 24px 22px;
	border: 1px solid #ddd;
	border-radius: 15px;
	margin: 0 0 18px;

	.custom-form-group {
		display: flex;
		align-items: center;
		margin: 0 0 14px;

		label {
			font-size: 12px;
			line-height: 12px;
			margin: 0;
			font-weight: 700;
			min-width: 190px;
			color: #000;
		}
		.ant-form-item {
			margin: 0;
			max-width: 550px;
			width: 100%;
		}
	}
}
.jn-heading {
	font-size: 14px;
	line-height: 16px;
	margin: 0 0 8px;
	font-weight: 700;
}
.mn-customizations {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.customizations-box {
		width: calc(100% / 2 - 14px);
		margin-bottom: 20px;

		.ant-form-item {
			margin: 0;
		}
		h4 {
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			margin: 0 0 6px;
		}
		p {
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
			margin: 0 0 10px;
		}
	}
}
.rfq-modal {
	position: fixed;
	z-index: 1059;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(29, 29, 29, 0.7);
	overflow-x: hidden;
	overflow-y: auto;

	.rfq-modal-dialog {
		min-height: calc(100% - 20px * 2);
		max-width: 585px;
		padding: 0 10px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.rfq-mmodal-content {
		background-color: #fff;
		width: 100%;
		border-radius: 15px;

		.rfq-modal-header {
			padding: 22px;
			padding-right: 50px;
			position: relative;

			.rfq-close {
				position: absolute;
				z-index: 1;
				right: 18px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				color: #808080;
			}

			+ .rfq-modal-body {
				padding-top: 0;
			}
		}
		.rfq-modal-body {
			padding: 22px;
			padding-top: 0;

			.SortableItem {
				.custom-form-group {
					margin: 0 0 14px;
				}
			}
		}
	}
}
.SortableItem {
	.custom-form-group {
		display: block;
		margin: 0;
		label {
			margin-right: 0;
			min-width: auto;
			span {
				color: var(--dove-gray);
				font-weight: 600;
				margin-left: 0;
			}
			.label-required {
				color: var(--flamingo);
			}
		}
		.label-required {
			color: var(--flamingo);
		}
	}
}
.fb-edit-form {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	right: 0;
	z-index: -1059;
	background: rgba(29, 29, 29, 0.7);
	transition: all 0.1s 0.3s ease;

	.fb-form-inner {
		max-width: 670px;
		width: 100%;
		height: 100%;
		background: #fff;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 70px 50px 50px;
		margin-left: -670px;
		transition: all 0.3s ease;

		.fb-form-header {
			margin: 0 0 20px;
			display: flex;
			justify-content: space-between;

			h4 {
				font-size: 16px;
				line-height: 16px;
				font-weight: 700;
				margin: 0;
				margin-right: 10px;
			}
			.fb-form-close {
				cursor: pointer;
				color: #808080;
			}
		}
	}
	.custom-form-group {
		display: block;

		.rdw-editor-main {
			border: 1px solid #f1f1f1;
			border-top: 0;
		}
		label {
			margin: 0 0 8px;
		}
	}
	.dynamic-option-list {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: flex;
				align-items: center;
				margin: 0 0 14px;

				label {
					margin: 0;
					font-size: 14px;
					line-height: 14px;
					font-weight: 700;
				}

				.custom-form-group {
					margin: 0;
				}
				.fb-options {
					flex: 0 1 240px;
					margin-right: 20px;
				}
				.fb-value {
					flex: 0 1 90px;
					margin-right: 50px;
				}
				.fb-btn {
					button {
						+ button {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1199px) {
	.send-msg-card {
		.sm-left {
			&.sm-left-form-builder {
				max-width: 100%;
				margin-right: 0;
				margin-bottom: 14px;
			}
		}
		.sm-right {
			&.sm-right-form-builder {
				margin-top: 0;
				width: 100%;
			}
		}
		.sm-demo-bar {
			max-width: 474px;
		}
		.sm-form-builder {
			.fb-left {
				max-width: 474px;

				.fb-preview {
					min-height: 260px;
				}
			}
			.fb-right {
				margin-left: 10px;
			}
		}
	}
}
@media (max-width: 991px) {
	.mn-customizations {
		.customizations-box {
			width: 100%;
		}
	}
}
@media (max-width: 767px) {
	.send-msg-card {
		padding: 18px;

		.sm-left {
			margin-right: 0;
			order: 1;

			&.sm-left-form-builder {
				order: 0;
			}
		}
		.sm-right {
			margin: 0 0 14px;
		}
		.custom-form-group {
			flex-wrap: wrap;

			label {
				margin-bottom: 4px;
				margin-right: 0;
				min-width: 100%;
			}
			&.custom-checkbox {
				label {
					width: 100%;
				}
			}
		}
		.sm-card-bottom {
			order: 2;
			.sm-btn-grp {
				flex-direction: column;

				button {
					+ button {
						margin-left: 0;
						margin-top: 14px;
					}
				}
			}
		}
		.sm-demo-bar {
			align-items: flex-start;
			flex-direction: column;

			.sm-heading {
				margin-right: 0;
				margin-bottom: 8px;
			}
		}
		.sm-form-builder {
			flex-wrap: wrap;

			.fb-left {
				max-width: 100%;
				order: 1;

				.fb-preview {
					padding: 8px;
					min-height: auto;
				}
			}
			.fb-right {
				margin-left: 0;
				margin-top: 0;
				margin-bottom: 10px;

				.fb-right-toolbar {
					ul {
						display: flex;
						flex-wrap: wrap;

						li {
							margin: 0 4px 4px 0;
							padding: 6px 10px;
							font-size: 12px;
							font-weight: 600;

							i {
								margin: 0 6px 0 0;
							}
						}
					}
				}
			}
		}
	}
	.msg-notification {
		padding: 18px 16px;

		.custom-form-group {
			display: block;

			label {
				margin-bottom: 4px;
			}
		}
	}
	.msg-tab-btnGrp {
		display: flex;
		flex-direction: column;

		.msg-tab-btn {
			min-width: 100%;

			+ .msg-tab-btn {
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}
	.fb-edit-form {
		.fb-form-inner {
			max-width: 100%;
			padding: 20px;
			margin-left: -100%;
		}
		.dynamic-option-list {
			ul {
				li {
					.fb-options {
						flex: 0 1 150px;
						margin-right: 6px;
					}
					.fb-value {
						flex: 0 1 70px;
						margin-right: 8px;
					}
					.fb-btn button {
						+ button {
							margin-left: 8px;
						}
					}
				}
			}
		}
	}
}
