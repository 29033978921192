.notification-list-wrap {
  width: 350px;
  margin: -12px -16px 0;
  .ant-list-items {
    max-height: 344px;
    overflow: hidden;
  }
  .ant-list-item {
    font-weight: 400;
    font-size: 12px;
    color: #000;
    margin-bottom: 0;
    line-height: 16px;
    padding: 14px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .notification-icon {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-right: 14px;
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h4 {
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      text-align: center;
      margin: 0;
      color: #6e86b3;
    }
  }
  .notification-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #000;

    .notification-text-blue {
      color: var(--theme-primary);
      font-weight: 700;
    }
    .notification-time {
      display: block;
      color: #999;
    }
  }
}
.notification-view-more {
  background: #f5f5f5;
  text-align: center;
  border-radius: 0 0 4px 4px;
  margin: 0 -16px -12px;

  a {
    padding: 14px;
    display: block;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }
}
.notification-page-wrap {
  .ant-list-item-meta-description {
    font-weight: 400;
    font-size: 14px;
    color: #8d99a3;
    margin-bottom: 0;
    padding-top: 5;
  }
  .ant-list-pagination {
    text-align: left;
    padding-left: 20px;
  }
  .ant-pagination-total-text {
    font-weight: 400;
    font-size: 14px;
    color: #8d99a3;
  }
}
.notification-wrap {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 24px 22px;

  .notification-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 8px;

    .custom-image-wrap {
      border-radius: 50%;
      font-size: 22px;
      overflow: hidden;
      box-shadow: 0.5px 1px 1px hsl(220deg 60% 50% / 0.7);
    }
    .notification-header-text {
      .nht-name {
        font-size: 22px;
        line-height: 22px;
        font-weight: 700;
        margin: 0 0 4px;
      }
      .nht-count {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  .ant-list-item {
    padding: 0;
    border: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
      .notification-text {
        border-bottom: 0;
      }
    }
  }
  .notification-icon {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-right: 14px;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.5px 1px 1px hsl(220deg 60% 50% / 0.7);

    h4 {
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      text-align: center;
      margin: 0;
      color: #6e86b3;
    }
  }
  .notification-text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #000;
    padding: 14px 0;
    border-bottom: 1px solid #e6e6e6;
    flex: 1;

    .notification-text-blue {
      color: var(--theme-primary);
      font-weight: 700;
    }
    .notification-time {
      display: block;
      color: var(--oslo-gray);
      font-size: 14px;
      margin-top: 2px;
    }
  }
}

@media (max-width: 767px) {
  .notification-wrap {
    padding: 18px 16px;

    .notification-header {
      flex-wrap: wrap;
      justify-content: center;

      .notification-header-text {
        flex: 0 0 100%;
        text-align: center;
      }
      .custom-image-wrap {
        margin: 0 0 14px;
      }
    }
    .notification-text {
      font-size: 14px;
      line-height: 18px;
      padding: 10px 0;
      flex: 1 1;

      .notification-time {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
}
